let shadowRoot;
let primaryAssets = localStorage.getItem("primaryAssets");
let backupAssets = localStorage.getItem("backupAssets");
let gifts = localStorage.getItem("gifts");
let amountChild = localStorage.getItem("amountChild");
let amountPet = localStorage.getItem("amountPet");
let maxFields = 10;
let user_fn = localStorage.getItem("user_fn");
let user_ln = localStorage.getItem("user_ln");
let user_email = localStorage.getItem("user_email");
let user_phone = localStorage.getItem("user_phone");
let birthDInputs;
let hasMinorChild = localStorage.getItem('hasMinorChild');
const year = new Date().getFullYear();

if(primaryAssets == '' || primaryAssets == null) primaryAssets = 1;
if(gifts == '' || gifts == null) gifts = 1;
if(backupAssets == '' || backupAssets == null) backupAssets = 1;
if(amountChild == '' || amountChild == null) amountChild = 1;
if(amountPet == '' || amountPet == null) amountPet = 1

window.addEventListener('heyflow-init', (event) => {
    shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
    
    // --- Next conditonal screens
    
    //track storage Children born date
    birthDInputs = shadowRoot.querySelectorAll('section[name="children-info"] .date-picker-input');
    for (let i = 0; i < birthDInputs.length; i++){
        birthDInputs[i].addEventListener("input", function(){
            let date = birthDInputs[i].value;
            let formatedDate = date.split('/');
            let year = formatedDate.pop();
            localStorage.setItem(`childrenBirth_${i}`, year);
        });
    }
    
    //track storage pets name
    for (let i = 1; i <= maxFields; i++){
        let targetPet = shadowRoot.querySelector(`#id-3734a096 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${i}) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1) > div.block-content.input-box.input-block.inner-narrow.show-error-on-nav > div > div > div.input-inner > div:nth-child(1) > input[type=text]`)
        targetPet.addEventListener("input", function(){
            localStorage.setItem(`pet_${i}_name`, targetPet.value);
        });
        
    }
    
    // ----- End Conditional Screens
    
    // ----- Add + Remove Options
    
    //add primary assets
    const addPrimaryAs = shadowRoot.querySelector('#add_pri_ben');
    addPrimaryAs.addEventListener("click", function(){
        if(primaryAssets < maxFields){
            primaryAssets++;
            localStorage.setItem("primaryAssets", primaryAssets);
            let newFields = shadowRoot.querySelectorAll(`section[name="primary-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${primaryAssets})`);
            for (let i = 0; i < newFields.length; i++) {
                newFields[i].classList.add("enabled");
            }
            if(primaryAssets > 1) removePrimaryAs.parentNode.style.display = 'block';
        }
    });
    //remove primary assets
    const removePrimaryAs = shadowRoot.querySelector('#remove_pri_ben');
    removePrimaryAs.addEventListener("click", function(){
        if(primaryAssets > 1){
            let newFields = shadowRoot.querySelectorAll(`section[name="primary-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${primaryAssets})`);
            let cleanField = shadowRoot.querySelectorAll(`section[name="primary-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${primaryAssets}) input.multiple-choice-option-input`);
             for (let i = 0; i < newFields.length; i++) {
                newFields[i].classList.remove("enabled");
                cleanField[i].value = "";
            }
            primaryAssets--;
            localStorage.setItem("primaryAssets", primaryAssets);
            if(primaryAssets <= 1) removePrimaryAs.parentNode.style.display = 'none';
        }
    });

    if(primaryAssets > 1) removePrimaryAs.parentNode.style.display = 'block';
    
     //add Gift assets
    const addGift = shadowRoot.querySelector('#add_gift');
    addGift.addEventListener("click", function(){
        if(gifts < maxFields){
            gifts++;
            localStorage.setItem("gifts", gifts);
            let newFields = shadowRoot.querySelectorAll(`section[name="gift-recipient"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${gifts})`);
            for (let i = 0; i < newFields.length; i++) {
                newFields[i].classList.add("enabled");
            }
            if(gifts > 1) removeGift.parentNode.style.display = 'block';
        }
    });
    
    //remove Gift assets
    const removeGift = shadowRoot.querySelector('#remove_gift');
    removeGift.addEventListener("click", function(){
        if(gifts > 1){
            let newFields = shadowRoot.querySelectorAll(`section[name="gift-recipient"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${gifts})`);
            let cleanField = shadowRoot.querySelectorAll(`section[name="gift-recipient"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${gifts}) input.multiple-choice-option-input`);
             for (let i = 0; i < newFields.length; i++) {
                newFields[i].classList.remove("enabled");
                cleanField[i].value = "";
            }
            gifts--;
            localStorage.setItem("gifts", gifts);
            if(gifts <= 1) removeGift.parentNode.style.display = 'none';
        }
    });
    if(gifts > 1) removeGift.parentNode.style.display = 'block';
    
    //add backup Assets
    const addBackup = shadowRoot.querySelector('#add_back_ben');
    addBackup.addEventListener("click", function(){
        if(backupAssets < maxFields){
            backupAssets++;
            localStorage.setItem("backupAssets", backupAssets);
            let newFields = shadowRoot.querySelectorAll(`section[name="backup-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${backupAssets})`);
            for (let i = 0; i < newFields.length; i++) {
                newFields[i].classList.add("enabled");
            }
            if(backupAssets > 1) removeBackup.parentNode.style.display = 'block';
        }
    });
    //remove backup Assets
    const removeBackup = shadowRoot.querySelector('#remove_back_ben');
    removeBackup.addEventListener("click", function(){
        if(backupAssets > 1){
            let newFields = shadowRoot.querySelectorAll(`section[name="backup-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${backupAssets})`);
            let cleanField = shadowRoot.querySelectorAll(`section[name="backup-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${backupAssets}) input.multiple-choice-option-input`);
             for (let i = 0; i < newFields.length; i++) {
                newFields[i].classList.remove("enabled");
                cleanField[i].value = "";
            }
            backupAssets--;
            localStorage.setItem("backupAssets", backupAssets);
            if(backupAssets <= 1) removeBackup.parentNode.style.display = 'none';
        }
    });
    if(backupAssets > 1) removeBackup.parentNode.style.display = 'block';
    
    //add child
    const addChild = shadowRoot.querySelector('#more-child');
    addChild.addEventListener("click", function(){
        if(amountChild < maxFields){
            amountChild++;
            localStorage.setItem("amountChild", amountChild);
            let newFields = shadowRoot.querySelector(`section[name="children-info"] > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${amountChild})`);
            newFields.classList.remove("hide_mobile","hide_desktop");
            if(amountChild > 1) removeChild.parentNode.style.display = 'block';
        }
    });
    
     //remove Child 
    const removeChild = shadowRoot.querySelector('#remove-child');
    removeChild.addEventListener("click", function(){
        if(amountChild > 1){
            let newFields = shadowRoot.querySelector(`section[name="children-info"] > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${amountChild})`);
            newFields.classList.add("hide_mobile","hide_desktop");
            let subFields = shadowRoot.querySelectorAll(`section[name="children-info"] > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${amountChild}) input`);
            subFields.forEach((subField) => {
                subField.value = "";
                subField.checked = false;
            });
            localStorage.removeItem(`childrenBirth_${amountChild-1}`);
            amountChild--;
            localStorage.setItem("amountChild", amountChild);
            if(amountChild <= 1) removeChild.parentNode.style.display = 'none';
        }
    });
    if(amountChild > 1) removeChild.parentNode.style.display = 'block';
    
    //add second backup child
    const addSecBackup = shadowRoot.querySelectorAll('.add-sec-guardian');
    addSecBackup.forEach((addBtn) => {
        addBtn.addEventListener("click", function(){
            let target = this.getAttribute('data-target');
            let opposite = this.getAttribute('data-oposite');
            let self= this.getAttribute('data-self');
            shadowRoot.querySelector(target).classList.remove('hide_mobile','hide_desktop');
            shadowRoot.querySelector(opposite).style.display = "block";
            shadowRoot.querySelector(self).style.display = "none";
        });
    });
    
    //remove second backup child
    const removeSecBackup = shadowRoot.querySelectorAll('.remove-sec-guardian');
    removeSecBackup.forEach((removeBtn) => {
        removeBtn.addEventListener("click", function(){
            let target = this.getAttribute('data-target');
            let opposite = this.getAttribute('data-oposite');
            let self= this.getAttribute('data-self');
            shadowRoot.querySelector(target).classList.add('hide_mobile','hide_desktop');
            shadowRoot.querySelector(opposite).style.display = "block";
            shadowRoot.querySelector(self).style.display = "none";
        });
    });
    
    //add Pet
    const addPet = shadowRoot.querySelector('#more-pets');
    addPet.addEventListener("click", function(){
        if(amountPet < maxFields){
            amountPet++;
            localStorage.setItem("amountPet", amountPet);
            let newFields = shadowRoot.querySelector(`section[name="pets-name"] div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${amountPet})`);
            newFields.classList.remove("hide_mobile","hide_desktop");
            if(amountPet > 1) removePet.parentNode.style.display = 'block';
        }
    });
    
    //remove Pet 
    const removePet = shadowRoot.querySelector('#remove-pets');
    removePet.addEventListener("click", function(){
        if(amountPet > 1){
            let newFields = shadowRoot.querySelector(`section[name="pets-name"] div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${amountPet})`);
            newFields.classList.add("hide_mobile","hide_desktop");
            let subFields = shadowRoot.querySelectorAll(`section[name="pets-name"] > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${amountPet}) input`);
            subFields.forEach((subField) => {
                subField.value = "";
                subField.checked = false;
            });
            localStorage.removeItem(`pet_${amountPet}_name`);
            amountPet--;
            localStorage.setItem("amountPet", amountPet);
            if(amountPet <= 1) removePet.parentNode.style.display = 'none';
        }
    });
    if(amountPet > 1) removePet.parentNode.style.display = 'block';
    
    // ------ End Add + Remove Options
    
    // ----- Input Value Formarter
    
    //get backspace erasing
    let backspace = false;
    shadowRoot.addEventListener("keydown", function(event){
        var KeyID = event.keyCode;
           switch(KeyID)
           {
              case 8:
                backspace = true;
              break; 
              case 46:
                backspace = true;
              break;
              default:
                backspace = false;
              break;
           }
    });
    shadowRoot.addEventListener("keyup", function(event){
        var KeyID = event.keyCode;
           switch(KeyID)
           {
              case 8:
                backspace = false;
              break; 
              case 46:
                backspace = false;
              break;
              default:
                backspace = false;
              break;
           }
    });
    
    //primary assets formater
    let allPriVal = shadowRoot.querySelectorAll(`section[name="primary-assets"] .multiple-choice-inner .multiple-choice-option-container input.multiple-choice-option-input`);
    
    function primary_asset_formater(init){
        [].forEach.call(allPriVal, function(input) {
            if(init){
                ['input','mouseout'].forEach( function(evt) {
                    input.addEventListener(evt, function(){
                        if(!backspace) {
                            let val = input.value;
                            let formatedVal = val.replace(/\*/g, "");
                            if(formatedVal != "") input.value = formatedVal+"*";
                            else input.value = formatedVal;
                        }
                    });
                });
            }
            else{
                let val = input.value;
                let formatedVal = val.replace(/\*/g, "");
                if(formatedVal != "") input.value = formatedVal+"*";
                else input.value = formatedVal;
            }
        });
    }
    primary_asset_formater(true);
    
    
    //backup assets formater
    let allBackVal = shadowRoot.querySelectorAll(`section[name="backup-assets"] .multiple-choice-inner .multiple-choice-option-container input.multiple-choice-option-input`);
    
    function backup_asset_formater(init){
        [].forEach.call(allBackVal, function(input) {
            if(init){
                ['input','mouseout'].forEach( function(evt) {
                    input.addEventListener(evt, function(){
                        if(!backspace) {
                            let val = input.value;
                            let formatedVal = val.replace(/\*/g, "");
                            if(formatedVal != "") input.value = formatedVal+"*";
                            else input.value = formatedVal;
                        }
                    });
                });
            }
            else{
                let val = input.value;
                let formatedVal = val.replace(/\*/g, "");
                if(formatedVal != "") input.value = formatedVal+"*";
                else input.value = formatedVal;
            }
        });
    }
    backup_asset_formater(true);
    
    //gift assets formater
    let allGiftsVal = shadowRoot.querySelectorAll(`section[name="gift-recipient"] .multiple-choice-inner .multiple-choice-option-container input.multiple-choice-option-input`);
    
    function gift_formater(init){
        [].forEach.call(allGiftsVal, function(input) {
            if(init){
                ['input','mouseout'].forEach( function(evt) {
                    input.addEventListener(evt, function(){
                        if(!backspace) {
                            let val = input.value;
                            let formatedVal = val.replace(/\*/g, "");
                            if(formatedVal != "") input.value = formatedVal+"*";
                            else input.value = formatedVal;
                        }
                    });
                });
            }
            else{
                let val = input.value;
                let formatedVal = val.replace(/\*/g, "");
                if(formatedVal != "") input.value = formatedVal+"*";
                else input.value = formatedVal;
            }
        });
    }
    gift_formater(true);
    

    //get click out of the inputs
    shadowRoot.addEventListener("click", function(){
        primary_asset_formater(false);
        backup_asset_formater(false);
        gift_formater(false);
    });
    
    
    // ----- End Input Value Formarter
});

//Get and Set and storage on change
window.addEventListener('heyflow-change', (event) => {
    // console.log('heyflow submit:', event.detail);
    //console.log(event.detail.fieldsSimple);
    
    //storage user email
    localStorage.setItem("user_email", event.detail.fieldsSimple.email_address);
    user_email = event.detail.fieldsSimple.email_address;
    
    //storage user name
    localStorage.setItem("user_fn", event.detail.fieldsSimple.first_name);
    user_fn = event.detail.fieldsSimple.first_name;
    
    localStorage.setItem("user_ln", event.detail.fieldsSimple.last_name);
    user_ln = event.detail.fieldsSimple.last_name;
    
    //storage user phone
    localStorage.setItem("user_phone", event.detail.fieldsSimple.phone);
    user_phone = event.detail.fieldsSimple.phone;
    
    //track user DD_desire
    localStorage.setItem("user_dd_desire", event.detail.fieldsSimple.upload_dd_desire);
    
    //track and storage relationship status
    localStorage.setItem("relStatus", event.detail.fieldsSimple.relationship);
    
    //track and storage wartime answers
    localStorage.setItem("wartime", event.detail.fieldsSimple.wartime);
    
    //track and storage children info
    localStorage.setItem("hasChildren", event.detail.fieldsSimple.children);
    
    //track and storage gift answer
    localStorage.setItem("giftBtn", event.detail.fieldsSimple.leave-gifts);
    
    //track and storage wishGuardians
    localStorage.setItem("wishGuardians", event.detail.fieldsSimple.want_guardians);
    
    //track and storage sameGuardChrildren
    localStorage.setItem("sameGuardChrildren", event.detail.fieldsSimple.same_guardians);
    
    //track and storage wish Pets Guardians
    localStorage.setItem("wishPetsGuardians", event.detail.fieldsSimple.guardians_for_pets);
    
    //track and storage sameGuardPets
    localStorage.setItem("sameGuardPets", event.detail.fieldsSimple.pets_same_guardians);
    

});

window.addEventListener('heyflow-screen-view', (event) => {
    //.log('heyflow screen view:', event.detail);
    
    //------ Return Conditonal Screens
    
    //pets + back conditional wishGuardians, Minor18.
    if(event.detail.screenName == "pets"){
        if(hasMinorChild){
            if(localStorage.getItem("wishGuardians") == 'Yes'){
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = "none";
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)').style.display = "none";
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)').style.display = "block";
            }
            else if(localStorage.getItem("wishGuardians") == 'No'){
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = "none";
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)').style.display = "block";
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)').style.display = "none";
            }
            else if(localStorage.getItem("wishGuardians") == null){
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = "block";
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)').style.display = "none";
                shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)').style.display = "none";
            }
        }
        else{
            shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = "block";
            shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)').style.display = "none";
            shadowRoot.querySelector('#id-dcf1735d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)').style.display = "none";
        }
    }
    
  
    //state + back conditional children + next conditional relationship
      /*
    if(event.detail.screenName == "state"){
        const haveChild = shadowRoot.querySelector('#id-5a70bb7d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)');
        const notHaveChild = shadowRoot.querySelector('#id-5a70bb7d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)');
        let isSingle = shadowRoot.querySelector('#id-5a70bb7d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)');
        let isMarried = shadowRoot.querySelector('#id-5a70bb7d > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4)');
        if(localStorage.getItem('hasChildren') == "Yes"){
            haveChild.style.display = 'block';
            notHaveChild.style.display = 'none';
        }
        else{
            haveChild.style.display = 'none';
            notHaveChild.style.display = 'block';
        }
        if(localStorage.getItem('relStatus') == "Married"){
            isSingle.style.display = 'none';
            isMarried.style.display = 'block';
        }else{
            isSingle.style.display = 'block';
            isMarried.style.display = 'none';
        }
    }
    */
    
    //phone-email + back conditional > needs to be update to another screen: basics-end
    /* change to basics-end, target screen 36 and 37, condition married or not
    if(event.detail.screenName == "phone-email"){
        let isMarried = shadowRoot.querySelector('#id-8fc16e02 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(12) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)');
        let isSingle = shadowRoot.querySelector('#id-8fc16e02 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(12) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)');
        if(localStorage.getItem('relStatus') == "Married"){
            isSingle.style.display = 'none';
            isMarried.style.display = 'block';
        }else{
            isSingle.style.display = 'block';
            isMarried.style.display = 'none';
        }
    }
    */
    
    //children text + relationship status
    if(event.detail.screenName == "children"){
        const singleR = shadowRoot.querySelector('#text-block-id-d52a2a7e');
        const marriedR = shadowRoot.querySelector('#text-block-id-ef3709d4');
        const singleBack = shadowRoot.querySelector('#generic-button-block-id-7cac3aba').parentNode;
        const marriedBack = shadowRoot.querySelector('#generic-button-block-id-d6d1780b').parentNode;
        let curRelS = localStorage.getItem("relStatus");
        if(curRelS == null || curRelS != 'Married'){
            marriedR.style.display = "none"; 
            singleR.style.display = "block";
            singleBack.style.display = "block";
            marriedBack.style.display = "none";
        }
        else if(curRelS == 'Married') {
            singleR.style.display = "none"; 
            marriedR.style.display = "block";
            marriedBack.style.display = "block";
            singleBack.style.display = "none";
        }
    }
    //wartime logic + trigger wartime condition
    //Needs to add more conditioons + making more complex
    if(event.detail.screenName == "medical-condition"){
        let wartime = localStorage.getItem("wartime");
        const warApproved = shadowRoot.querySelector('#id-07c65c6f > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)');
        const warReproved = shadowRoot.querySelector('#id-07c65c6f > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)');
        if(wartime == null){} //call user forgot to answer
        else if(wartime == 'No') warReproved.style.display = "block";
        else warApproved.style.display = 'block';
    }
    
    //track upload dd desire
    if(event.detail.screenName == "va"){
        const defaultBack = shadowRoot.querySelector('#id-0ee691aa > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)');
        const jumpBack = shadowRoot.querySelector('#id-0ee691aa > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(6) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)');
        if(localStorage.getItem("user_dd_desire") == 'now'){
            defaultBack.style.display = 'block';
            jumpBack.style.display = 'none';
        }
        else{ 
            jumpBack.style.display = 'block';
            defaultBack.style.display = 'none';
        }
    }
    
    //------ End Return Conditonal Screens
    
    //------ Check if already set
    
    
    //primary know assets
     if(event.detail.screenName == "primary-assets"){
          for (let i = 2; i <= primaryAssets; i++) {
            let newFields = shadowRoot.querySelectorAll(`section[name="primary-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${i})`);
                for (let j = 0; j < newFields.length; j++) {
                    newFields[j].classList.add("enabled");
                }
        }
    }
    //gifts know
    if(event.detail.screenName == "gift-recipient"){
        for (let i = 2; i <= gifts; i++) {
            let newFields = shadowRoot.querySelectorAll(`section[name="gift-recipient"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${i})`);
                for (let j = 0; j < newFields.length; j++) {
                    newFields[j].classList.add("enabled");
                }
        }
    }
     //backup know assets
    if(event.detail.screenName == "backup-assets"){
        for (let i = 2; i <= backupAssets; i++) {
            let newFields = shadowRoot.querySelectorAll(`section[name="backup-assets"] .multiple-choice-inner .multiple-choice-option-container:nth-child(${i})`);
                for (let j = 0; j < newFields.length; j++) {
                    newFields[j].classList.add("enabled");
                }
        }
    }
    
    //Child Amount
    if(event.detail.screenName == "children-info"){
        for (let i = 2; i <= amountChild; i++) {
            let newFields = shadowRoot.querySelector(`section[name="children-info"] > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${i})`);
            newFields.classList.remove("hide_mobile","hide_desktop");
        }
        for (let i = 1; i <= maxFields; i++){
            let childOrigin = shadowRoot.querySelector(`section[name="children-info"] > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${i}) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)`);
            if(localStorage.getItem("relStatus") != "Married") childOrigin.style.display = "none";
            else childOrigin.style.display = "block";
                
        }
    }
    
    //Pet Amount
    if(event.detail.screenName == "pets-name"){
        for (let i = 2; i <= amountPet; i++) {
            let newFields = shadowRoot.querySelector(`section[name="pets-name"] div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${i})`);
            newFields.classList.remove("hide_mobile","hide_desktop");
        }
    }
    
    //recap screen
    if(event.detail.screenName == "recap"){
       //gift answer
       let giftRRecap = shadowRoot.querySelector(`section[name="recap"] span[data-variable="gift-recipient"]`);
       let giftRecap = shadowRoot.querySelector(`section[name="recap"] span[data-variable="gift"]`);
       if(localStorage.getItem('giftBtn') == null){
            giftRecap.innerHTML = "Ops! It seems you didn't answer this one, please go back to the question";
            giftRRecap.innerHTML = "";
       }
       else if(localStorage.getItem('giftBtn') == 'No'){
           giftRecap.innerHTML = "No gifts to be left";
           giftRRecap.innerHTML = "";
       }
       else if(localStorage.getItem('giftBtn') == 'Yes'){
           let recipient = giftRRecap.innerHTML;
           let formatedRecipient = recipient.replace(/\*,/g, "<br>");
           giftRRecap.innerHTML = formatedRecipient;
           
           let content = giftRecap.innerHTML;
           let formated = content.replace(/\*,/g, "<br>");
           giftRecap.innerHTML = formated;
       }
    }
    
    
    //check if child requires guardian
    if(event.detail.screenName == "basics-end"){
        hasMinorChild = false;
        if(localStorage.getItem('hasChildren') == "Yes"){
            shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)').style.display = 'block';
            shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)').style.display = 'none';
        }
        else{
            shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(1)').style.display = 'none';
            shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(2)').style.display = 'block';
            shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = 'block';
            shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4)').style.display = 'none';
        }
        
        if(localStorage.getItem('hasChildren') == "Yes"){
            for (let i = 0; i < birthDInputs.length; i++){
                let adult = year - localStorage.getItem(`childrenBirth_${i}`);
                if( adult < 17 ) {
                    hasMinorChild = true;
                }
                if(hasMinorChild){
                    shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = 'none';
                    shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4)').style.display = 'block';
                    localStorage.setItem('hasMinorChild', hasMinorChild);
                    break;
                }
                else if(i == birthDInputs.length - 1){
                    shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(3)').style.display = 'block';
                    shadowRoot.querySelector('#id-383e25c2 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4) > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(4)').style.display = 'none';
                    localStorage.setItem('hasMinorChild', hasMinorChild);
                    break;
                }
            }
        }
    }
    
    if(event.detail.screenName == "nominate-guardians"){
        for (let i = 0; i < birthDInputs.length; i++){
            let adult = year - localStorage.getItem(`childrenBirth_${i}`);
            
            if(localStorage.getItem("sameGuardChrildren") == 'No'){
                shadowRoot.querySelector(`#id-7255f938 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4})`).style.display = 'none';
                if( adult >= 18 )shadowRoot.querySelector(`#id-7255f938 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${5+i})`).style.display = 'none';
                else shadowRoot.querySelector(`#id-7255f938 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${5+i})`).style.display = 'block';
            }
            else if(localStorage.getItem("sameGuardChrildren") == 'Yes' || localStorage.getItem("sameGuardChrildren") == null){
                shadowRoot.querySelector(`#id-7255f938 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4})`).style.display = 'block';
                shadowRoot.querySelector(`#id-7255f938 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${5+i})`).style.display = 'none';
            }
        }
    }
    
    //check listed pets and offer guardians
    if(event.detail.screenName == "nominate-pets-guardian"){
        
        for (let i = 1; i <= maxFields; i++){
            let targetPet = localStorage.getItem(`pet_${i}_name`);
            if(localStorage.getItem("sameGuardPets") == 'No'){
                shadowRoot.querySelector(`#id-7aeb4071 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4})`).style.display = 'none';
                if(targetPet == "" || targetPet == null) shadowRoot.querySelector(`#id-7aeb4071 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4+i})`).style.display = 'none';
                else if(targetPet != "" && targetPet != null) shadowRoot.querySelector(`#id-7aeb4071 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4+i})`).style.display = 'block';
            }
            else if(localStorage.getItem("sameGuardPets") == 'Yes' || localStorage.getItem("sameGuardPets") == null){
                shadowRoot.querySelector(`#id-7aeb4071 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4})`).style.display = 'block';
                shadowRoot.querySelector(`#id-7aeb4071 > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div > div.block-content.elements-container.elements-v2-container.inner-full > div > div > div:nth-child(${4+i})`).style.display = 'none';
            }
        }
    }
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
    localStorage.clear();
});